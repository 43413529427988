/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import logo from './../../../assets/images/header/logo.png'
import language from './../../../assets/images/header/language.png'
import BotonContacto from '../buttons/boton-contacto'
import NavbarLinks from './navbar-links'
import BotonContactoResponsive from '../buttons/boton-contacto-responsive'
const Header = ({ cambiarIdioma }: any): any => {
  const { i18n } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [isSpanish, setIsSpanish] = useState(true)
  const onChangeLang = async (langCode: string): Promise<void> => {
    try {
      await i18n.changeLanguage(langCode)
      cambiarIdioma(langCode)
      setIsSpanish(langCode === 'es')
    } catch (error) {
      console.error('Error changing language:', error)
    }
  }
  if (location.pathname === '/politica-de-cookies') return null
  return (
    <nav className="fixed flex flex-row items-center justify-between w-full max-w-[1439px] mx-auto  md:px-[120px] py-[18px] top-0 bg-[#043873] z-50">
      <a href="/">
        <img src={logo} alt="Criterian" className="w-[180px] md:w-[250px]" />
      </a>
      <button onClick={() => { setMenuOpen(!menuOpen) }} className=" md:hidden text-white text-3xl mr-[1%] ">☰</button>
      {menuOpen && (
         <div className="fixed top-0 left-0 right-0 mt-[70px] bg-[#043873] flex flex-col items-center justify-start space-y-[32px] text-white text-xl h-auto max-h-fit py-6">
          <div className='w-full place-items-center'>
          <NavbarLinks color='white' variant="menu" onClick={() => { setMenuOpen(false) }}/>
          </div>
          <img src={language}onClick={() => { void onChangeLang(isSpanish ? 'en' : 'es') }}alt="Language"className="w-[40px] h-[40px] cursor-pointer"/>
          <BotonContactoResponsive texto='CONTACTO' />
        </div>
      )}
      <div className="hidden md:flex ml-32 whitespace-nowrap">
        <NavbarLinks color='white' variant="header" />
        </div>
        <div className="hidden md:flex ml-72">
        <BotonContacto texto='CONTACTO' />
        </div>
        <div className="hidden md:flex">
        <img src={language} onClick={() => { void onChangeLang(isSpanish ? 'en' : 'es') }} alt="Language" className="w-[40px] h-[40px] cursor-pointer"/>
        </div>
    </nav>
  )
}
export default Header
