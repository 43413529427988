import React from 'react'
import Text from '../../shared/textStyleDefault/texto'

const FormularioContacto: React.FC = () => {
  return (
    <form
      id="contact-form"
      className=" w-auto md:!w-[490.26px] h-[573.63px] flex flex-col justify-center items-center gap-[24.38px] mb-28 md:mb-14"
    >
      <label htmlFor="nombre" className=" mt-20 self-start">
        <Text as='h6'>Nombre</Text>
      </label>
      <input
        type="text"
        name="nombre"
        className="w-full bg-[#F7F7F7] p-1 text-lg"
        required
      />

      <label htmlFor="telefono" className="self-start">
        <Text as='h6'>Teléfono</Text>
      </label>
      <input
        type="tel"
        name="telefono"
        className="w-full bg-[#F7F7F7] p-1 text-lg"
      />

      <label htmlFor="email" className="self-start">
        <Text as='h6'>Email</Text>
      </label>
      <input
        type="email"
        name="email"
        className="w-full bg-[#F7F7F7] p-1 text-lg"
        required
      />

      <label htmlFor="mensaje" className="self-start">
        <Text as='h6'>Mensaje</Text>
      </label>
      <textarea
        name="mensaje"
        className="w-full bg-[#F7F7F7] p-16 text-lg"
        required
      ></textarea>

      <button type="submit" className="w-36 bg-[#FFE492] py-3 rounded-lg ">
        <Text as='button' className='text-[#043873]'>Enviar</Text>
      </button>
    </form>
  )
}

export default FormularioContacto
