import React from 'react'
import vector from '../../../assets/images/inicio/Vector.png'
import clsx from 'clsx'
import Text from '../textStyleDefault/texto'
interface UnderlinedTitleProps {
  texto: string
  palabrasSubrayadas: string
  blanco: boolean
  width: string
  divWidth?: string
  marginTop: string
}
const UnderlinedTitle = ({ texto, palabrasSubrayadas, blanco, width, divWidth, marginTop }: UnderlinedTitleProps): any => {
  const startIndex = texto.indexOf(palabrasSubrayadas)
  const endIndex = startIndex + palabrasSubrayadas.length
  const beforeText = texto.substring(0, startIndex)
  const underlinedText = texto.substring(startIndex, endIndex)
  const afterText = texto.substring(endIndex)
  return (
    <div className=' flex items-start w-[85%] ml-1 md:ml-20 md:mr-auto mt-[3%] gap-[60px]' style={{ marginTop, width: divWidth }}>
      <Text as='h3' className={clsx('relative max-w-[70%] mt-[20px] mb-[3%] md:ml-6', { 'text-white': blanco })} style={{ maxWidth: width }}>
      {beforeText}
      <span className="relative inline-block">
        {underlinedText}
        <img
          src={vector}
          alt="subrayado"
          className="absolute left-8 md:left-14 bottom-0 w-full translate-y-[100%] md:scale-x-115"
        />
      </span>
      {afterText}
    </Text>
    </div>
  )
}
export default UnderlinedTitle
