import consultoria from '../../assets/images/inicio/image-consultoria.png'
import desarrollo from '../../assets/images/inicio/image-desarrollo-software.png'
import gestion from '../../assets/images/inicio/image-gestion-productos.png'
import soporte from '../../assets/images/inicio/image-soporte-operaciones.png'
import arquitectura from '../../assets/images/inicio/image-arquitectura.png'
import estrategia from '../../assets/images/inicio/image-estrategia.png'
import adaptabilidad from '../../assets/images/inicio/icon-adaptabilidad.png'
import apoyo from '../../assets/images/inicio/icon-apoyo.png'
import experiencia from '../../assets/images/inicio/icon-experiencia.png'
import soluciones from '../../assets/images/inicio/icon-soluciones.png'

import logoArbor from '../../assets/images/casosExito/logo-arbor.png'
import imagenArbor from '../../assets/images/casosExito/mockup_arbor.png'

import logoRFHE from '../../assets/images/casosExito/logo-rfhe.png'
import imagenRFHE from '../../assets/images/casosExito/mockup_rfhe.png'

import logoEntrenaVirtual from '../../assets/images/casosExito/logo-entrena-virtual.svg'
import imagenEntrenaVirtual from '../../assets/images/casosExito/mockup_entrena.png'

import logoSavso from '../../assets/images/casosExito/logo-savso.png'
import imagenSavso from '../../assets/images/casosExito/mockup_savso.png'

import logoGaia from '../../assets/images/casosExito/logo-gaia.png'
import logoFUE from '../../assets/images/casosExito/logo-fue.png'
import imagenGaia from '../../assets/images/casosExito/mockup_gaia.png'

import logoPella from '../../assets/images/casosExito/logo-pella.png'
import imagenPella from '../../assets/images/casosExito/mockup_pella-2.png'

import logoAxis from '../../assets/images/casosExito/logo-axispharma.png'
import imagenAxis from '../../assets/images/casosExito/mockup_axispharma.png'

import googlePlayImagen from '../../assets/images/casosExito/googleplay.png'
import appleStoreImagen from '../../assets/images/casosExito/appstore.png'

import alineacion from '../../assets/images/desarrollo/icon-alineacion.png'
import eficiencia from '../../assets/images/desarrollo/icon-eficiencia.png'
import resiliencia from '../../assets/images/desarrollo/icon-resiliencia.png'
import sostenible from '../../assets/images/desarrollo/icon-sostenible.png'

const razones =
[
  {
    icono: soluciones,
    titulo: 'Soluciones Prácticas',
    texto: 'Ofrecemos soluciones que se adaptan a tu negocio, priorizando la practicidad para simplificar tus operaciones diarias.'
  },
  {
    icono: experiencia,
    titulo: 'Experiencia Confiable',
    texto: 'Con [número] años de experiencia, hemos aprendido lo que realmente importa. Nuestra trayectoria habla por sí misma.'
  },
  {
    icono: apoyo,
    titulo: 'Adaptabilidad a tu medida',
    texto: 'Creemos en la flexibilidad. Nuestro software se adapta a tus necesidades, no al revés. Personalización real para empresas reales.'
  },
  {
    icono: adaptabilidad,
    titulo: 'Apoyo Genuino',
    texto: 'Nuestro compromiso va más allá del software. Estamos aquí para brindarte un apoyo genuino en cada paso, porque tu éxito es el nuestro.'
  }
]
const servicios =
[
  {
    imagen: consultoria,
    titulo: 'Consultoría',
    texto: 'Navegamos justos hacia soluciones empresariales.',
    textoBoton: 'Explora soluciones estratégicas'
  },
  {
    imagen: desarrollo,
    titulo: 'Desarrollo de Software',
    texto: 'Codigo creativo, soluciones sorprendentes',
    textoBoton: 'Haz realidad tus ideas'
  },
  {
    imagen: gestion,
    titulo: 'Gestión de productos',
    texto: 'De la idea al éxito: gestionamos, optimizamos, triunfamos',
    textoBoton: 'Potencia tus productos'
  },
  {
    imagen: soporte,
    titulo: 'Soporte de operaciones',
    texto: 'Operaciones fluyendo sin obstáculos, siempre',
    textoBoton: 'Optimizar operaciones'
  },
  {
    imagen: arquitectura,
    titulo: 'Arquitectura de Software',
    texto: 'Construyendo bases sólidas',
    textoBoton: 'Descubre nuestras soluciones de arquitectura'
  },
  {
    imagen: estrategia,
    titulo: 'Estrategia TI',
    texto: 'Construyendo estrategias digitales para el futuro',
    textoBoton: 'Descubre tu estrategia TI'
  }
]

const casosexito =
[
  {
    logos: [logoArbor],
    nombre: 'Arbor',
    urls: ['https://www.arborfintech.com'],
    texto: 'Arbor Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis est, lacinia vitae enim ut, venenatis porta libero. Curabitur eleifend congue commodo. Cras vitae tortor ultrices, eleifend ligula ut, posuere ipsum. Suspendisse quis fermentum lectus, in varius urna. Curabitur ut mollis purus. Donec tincidunt, mi at sagittis sagittis, lectus odio convallis enim, eget mattis elit ex id mi. Maecenas vitae lorem non justo semper lobortis.',
    imagen: imagenArbor,
    googlePlay: { imagen: googlePlayImagen, enlace: 'https://play.google.com/store/apps/details?id=com.arbor.arbor&utm_source=landing1_bot%C3%B3n1&pli=1' },
    appleStore: { imagen: appleStoreImagen, enlace: 'https://apps.apple.com/app/apple-store/id1265936537?utm_source=landing1_bot%C3%B3n1' }
  },
  {
    logos: [logoRFHE],
    nombre: 'Real Federación Hípica Española',
    urls: ['https://www.rfhe.com'],
    texto: 'textoRFHE Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis est, lacinia vitae enim ut, venenatis porta libero. Curabitur eleifend congue commodo. Cras vitae tortor ultrices, eleifend ligula ut, posuere ipsum. Suspendisse quis fermentum lectus, in varius urna. Curabitur ut mollis purus. Donec tincidunt, mi at sagittis sagittis, lectus odio convallis enim, eget mattis elit ex id mi. Maecenas vitae lorem non justo semper lobortis.',
    imagen: imagenRFHE,
    googlePlay: { imagen: '', enlace: false },
    appleStore: { imagen: '', enlace: false }
  },
  {
    logos: [logoEntrenaVirtual],
    nombre: 'Entrena Virtual',
    urls: ['https://www.entrenavirtual.es/app'],
    texto: 'textoEntrenaVirtual Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis est, lacinia vitae enim ut, venenatis porta libero. Curabitur eleifend congue commodo. Cras vitae tortor ultrices, eleifend ligula ut, posuere ipsum. Suspendisse quis fermentum lectus, in varius urna. Curabitur ut mollis purus. Donec tincidunt, mi at sagittis sagittis, lectus odio convallis enim, eget mattis elit ex id mi. Maecenas vitae lorem non justo semper lobortis.',
    imagen: imagenEntrenaVirtual,
    googlePlay: { imagen: googlePlayImagen, enlace: 'https://play.google.com/store/apps/details?id=com.entrenavirtual.app&pli=1' },
    appleStore: { imagen: appleStoreImagen, enlace: 'https://apps.apple.com/es/app/entrena-virtual/id1582517944?l=en' }
  },
  {
    logos: [logoSavso],
    nombre: 'Savso',
    urls: ['https://www.savso.es'],
    texto: 'textoSavso Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis est, lacinia vitae enim ut, venenatis porta libero. Curabitur eleifend congue commodo. Cras vitae tortor ultrices, eleifend ligula ut, posuere ipsum. Suspendisse quis fermentum lectus, in varius urna. Curabitur ut mollis purus. Donec tincidunt, mi at sagittis sagittis, lectus odio convallis enim, eget mattis elit ex id mi. Maecenas vitae lorem non justo semper lobortis.',
    imagen: imagenSavso,
    googlePlay: { imagen: '', enlace: false },
    appleStore: { imagen: '', enlace: false }
  },
  {
    logos: [logoGaia, logoFUE],
    nombre: 'Gaia Program',
    urls: ['https://www.gaianext.com', 'https://www.bridgenext.es'],
    texto: 'textoGaia Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis est, lacinia vitae enim ut, venenatis porta libero. Curabitur eleifend congue commodo. Cras vitae tortor ultrices, eleifend ligula ut, posuere ipsum. Suspendisse quis fermentum lectus, in varius urna. Curabitur ut mollis purus. Donec tincidunt, mi at sagittis sagittis, lectus odio convallis enim, eget mattis elit ex id mi. Maecenas vitae lorem non justo semper lobortis.',
    imagen: imagenGaia,
    googlePlay: { imagen: '', enlace: false },
    appleStore: { imagen: '', enlace: false }
  },
  {
    logos: [logoPella],
    nombre: 'Pella Athletika',
    urls: ['https://pellaathletika.com/'],
    texto: 'textoPella Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis est, lacinia vitae enim ut, venenatis porta libero. Curabitur eleifend congue commodo. Cras vitae tortor ultrices, eleifend ligula ut, posuere ipsum. Suspendisse quis fermentum lectus, in varius urna. Curabitur ut mollis purus. Donec tincidunt, mi at sagittis sagittis, lectus odio convallis enim, eget mattis elit ex id mi. Maecenas vitae lorem non justo semper lobortis.',
    imagen: imagenPella,
    googlePlay: { imagen: googlePlayImagen, enlace: 'https://play.google.com/store/apps/details?id=com.criterian.pella' },
    appleStore: { imagen: appleStoreImagen, enlace: 'https://apps.apple.com/au/app/pella/id1212159923' }
  },
  {
    logos: [logoAxis],
    nombre: 'Axis Pharma',
    urls: ['https://es.axispharma.com/'],
    texto: 'textoAxis Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis est, lacinia vitae enim ut, venenatis porta libero. Curabitur eleifend congue commodo. Cras vitae tortor ultrices, eleifend ligula ut, posuere ipsum. Suspendisse quis fermentum lectus, in varius urna. Curabitur ut mollis purus. Donec tincidunt, mi at sagittis sagittis, lectus odio convallis enim, eget mattis elit ex id mi. Maecenas vitae lorem non justo semper lobortis.',
    imagen: imagenAxis,
    googlePlay: { imagen: '', enlace: false },
    appleStore: { imagen: '', enlace: false }
  }
]

const valores =
[
  {
    titulo: 'Colaboración',
    texto: 'Trabajamos en conjunto hacia metas comunes, reconociendo que el éxito individual de cada miembro contribuye al éxito colectivo de Criterian.'
  },
  {
    titulo: 'Integridad',
    texto: 'Actuamos con integridad y decimos lo que pensamos. Nos comprometemos a ser honestos y transparentes en todas nuestras interacciones, creando una base sólida para relaciones personales y profesionales.'
  },
  {
    titulo: 'Apertura',
    texto: 'Nos mantenemos abiertos a nuevas ideas y perspectivas, alentando la diversidad de opiniones y no temiendo el desacuerdo mientras buscamos alcanzar un consenso.'
  },
  {
    titulo: 'Responsabilidad',
    texto: 'Asumimos la responsabilidad de nuestras decisiones y de las consecuencias que puedan derivarse de ellas, construyendo una cultura de confianza y confiabilidad.'
  },
  {
    titulo: 'Excelencia',
    texto: 'Seguimos estándares de calidad excepcionales en todo lo que hacemos y nos esforzamos por la mejora continua, impulsados por el deseo de superar las expectativas.'
  },
  {
    titulo: 'Respeto',
    texto: 'Escuchamos a nuestros colegas como profesionales, fomentando un ambiente donde se respeta la diversidad y se valora a cada individuo como ser humano.'
  }
]
const nuestroTrabajo =
[
  {
    titulo: 'Consulta Personalizada',
    texto: 'Nos sumergimos en una consulta personalizada, sentándonos contigo para comprender en profundidad tus objetivos empresariales y desafíos únicos. Este diálogo nos permite establecer una base sólida para el trabajo conjunto.'
  },
  {
    titulo: 'Análisis Detallado',
    texto: 'Realizamos un análisis exhaustivo de tu infraestructura actual, utilizando herramientas avanzadas y expertise técnico. Este proceso nos permite identificar oportunidades de mejora y proponer soluciones personalizadas.'
  },
  {
    titulo: 'Planificación Estratégica',
    texto: 'Basándonos en los resultados del análisis, desarrollamos una planificación estratégica detallada. Este plan está meticulosamente adaptado a tus necesidades específicas, asegurando una hoja de ruta clara y efectiva para alcanzar tus metas.'
  },
  {
    titulo: 'Implementación',
    texto: 'Pasamos de la planificación a la acción, implementando la estrategia con eficiencia y precisión. Nuestro enfoque está respaldado por profesionales altamente capacitados y herramientas tecnológicas de vanguardia para garantizar resultados tangibles y duraderos.'
  },
  {
    titulo: 'Seguimiento y ajustes continuos',
    texto: 'Después de la implementación, no dejamos nada al azar. Realizamos evaluaciones periódicas para medir el rendimiento y realizar ajustes continuos según sea necesario. Este ciclo de mejora constante garantiza que nuestra colaboración evolucione con tu empresa.'
  }
]
const descripcionBeneficios =
[
  {
    titulo: '',
    texto: 'Nuestra Estrategia TI se enfoca en comprender tus objetivos empresariales y alinearlos con soluciones tecnológicas efectivas. A través de un análisis exhaustivo, identificamos oportunidades de mejora, optimización de recursos y fortalecimiento de la infraestructura tecnológica.'
  }
]
const beneficios =
[
  {
    icono: alineacion,
    titulo: 'Alineación con objetivos',
    texto: 'Integración de tecnología para potenciar tus metas comerciales.'
  },
  {
    icono: eficiencia,
    titulo: 'Eficiencia Operativa',
    texto: 'Optimización de procesos y recursos digitales.'
  },
  {
    icono: resiliencia,
    titulo: 'Resiliencia Tecnológica',
    texto: 'Preparación para los desafíos futuros del mundo digital.'
  },
  {
    icono: sostenible,
    titulo: 'Innovación Sostenible',
    texto: 'Impulso constante hacia nuevas oportunidades tecnológicas.'
  }
]
export default { servicios, razones, valores, nuestroTrabajo, descripcionBeneficios, beneficios, casosexito }
