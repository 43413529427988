import flechaDerecha from '../../../assets/images/inicio/arrow-right.png'
import React from 'react'
import Text from '../../shared/textStyleDefault/texto' // Importa el componente Text

interface BotonServicioProps {
  texto: string
}

const BotonServicio = (props: BotonServicioProps): any => {
  return (
    <button className="w-auto max-w-[250px] md:w-auto md:max-w-full h-[40px] md:h-[60px] py-4 bg-blue-400 rounded-lg inline-flex justify-center items-center gap-1 overflow-hidden whitespace-nowrap text-ellipsis">
      <Text variant='body2' className='justify-start tracking-tight overflow-hidden whitespace-nowrap text-ellipsis  text-white pl-4 '>
        {props.texto}
      </Text>
      &nbsp;&nbsp;
      <img src={flechaDerecha} alt="inicio" />
    </button>
  )
}

export default BotonServicio
