/* eslint-disable react/react-in-jsx-scope */
import ContactoBanner from '../../shared/contactBanner/contacto-banner'
import Descripciones from '../../shared/descriptions/Descripciones'
import constantes from '../../constantes/constantes'
import Beneficios from './beneficios'
import Banner from '../../shared/frontBanner/banner'
import fondoBanner from '../../../assets/images/desarrollo/banner_image.png'
const servicios = constantes.nuestroTrabajo
const descripcionBeneficios = constantes.descripcionBeneficios
const Desarrollo = ({ idioma }: any): any => {
  return (
    <div>
      <Banner image={fondoBanner} title='Estrategia TI' subtitle='!DISEÑANDO EL FUTURO DIGITAL¡' description='En el mundo tecnológico en constante evolución, la correcta gestión de la infraestructura y los recursos digitales es esencial. Nuestro servicio de Estrategia TI está diseñado para guiar a tu empresa hacia el éxito en el entorno digital.'/>
      <Descripciones items={descripcionBeneficios} titulo='Beneficios' subrayado='Beneficios' blanco={false} />
      <Beneficios/>
      <Descripciones items={servicios} titulo='Como trabajamos' subrayado='trabajamos' blanco={false} />
      <ContactoBanner/>
    </div>
  )
}
export default Desarrollo
