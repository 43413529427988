import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
interface NavbarLinksProps {
  color: string
  variant?: 'header' | 'footer' | 'menu'
  onClick?: () => void
}
const NavbarLinks = (props: NavbarLinksProps): any => {
  const { t } = useTranslation()
  return (
    <ul className={clsx('flex', { 'flex-row space-x-[32px] w-48 mx-auto ': props.variant === 'header', ' items-start flex-col space-y-[16px] right-[10%]  ': props.variant === 'footer' || props.variant === 'menu', 'items-center ': props.variant === 'menu' })}>
     <li className={clsx('group', props.variant === 'header' && 'flex justify-center', (props.variant === 'footer' || props.variant === 'menu') && 'relative flex flex-col ', props.variant === 'menu' && 'items-center')}>
        <NavLink to='productos-y-servicios/' className={`p-2 flex items-center text-${props.color} !text-[20px] cursor-pointer`} onClick={props.onClick}>
          {t('servicios')}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='group-hover:rotate-180' fill={props.color}>
            <path d="M7.41 8.295L12 12.875L16.59 8.295L18 9.705L12 15.705L6 9.705L7.41 8.295Z"/>
          </svg>
        </NavLink>
        <ul className={clsx('rounded-[8px] font-normal cursor-pointer',
          props.variant === 'header' && 'w-max absolute text-left mt-[45px] border-white border-[1px] ml-[170px] hidden group-hover:block',
          props.variant === 'footer' && ' w-max mt-2 text-left hidden group-hover:block',
          props.variant === 'menu' && ' w-full mx-[-37.62%]  text-center mt-2 hidden group-hover:block',
          props.color === 'white' ? 'bg-[#043873]' : 'bg-white'
        )}>
          <li className={clsx('p-2', props.variant === 'menu' && 'hover:bg-blue-500 w-screen')}><NavLink to='productos-y-servicios/' className={clsx(`text-${props.color}`, props.variant === 'menu' ? 'text-[12px]' : 'text-[16px]')} onClick={props.onClick}>{t('desarrollo de software')}</NavLink></li>
          <li className={clsx('p-2', props.variant === 'menu' && 'hover:bg-blue-500 w-screen')}><a href='https://due-diligence.criterian.es/' className={clsx(`text-${props.color}`, props.variant === 'menu' ? 'text-[12px]' : 'text-[16px]')} target='_blank' rel='noreferrer'>{t('due')}</a></li>
        </ul>
      </li>
      <li className='block'><NavLink to='casos-de-exito/' className={`flex p-2 text-${props.color} !text-[20px]`} onClick={props.onClick}>{t('proyectos')}</NavLink></li>
      <li className='block'><NavLink to='la-empresa/' className={`flex p-2 text-${props.color} !text-[20px]`} onClick={props.onClick}>{t('nosotros')}</NavLink></li>
    </ul>
  )
}
export default NavbarLinks
