/* eslint-disable react/react-in-jsx-scope */
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import ContactoComp from '../../shared/footer/contacto'
import mapsIcon from '../../../assets/images/footer/location_on.png'
import mailIcon from '../../../assets/images/footer/email.png'
import FormularioContacto from './formulario'
import Text from '../../shared/textStyleDefault/texto'
import UnderlinedTitle from '../../shared/underlinedText/underlined-title'
const Contacto = (): any => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    const form = document.getElementById('contact-form')
    if (form != null) {
      form.addEventListener('submit', function (e) {
        e.preventDefault()
        const xhr = new XMLHttpRequest()
        xhr.open('POST', 'https://jfwz9jkeha.execute-api.eu-west-1.amazonaws.com/default/contact-form-lambda')
        xhr.addEventListener('load', function () {
          if (xhr.status === 200) {
            setShowSuccess(true)
            setShowError(false)
          } else {
            setShowError(true)
            setShowSuccess(false)
          }
        })
        xhr.addEventListener('error', function () {
          setShowError(true)
          setShowSuccess(false)
        })
        const contactForm = document.getElementById('contact-form') as HTMLFormElement
        const formData = new FormData(contactForm)
        const jsonData = JSON.stringify(Object.fromEntries(formData))
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(jsonData)
      })
    }
  }, [])
  return (
    <div className="mt-20 md:mt-[12%] relative min-h-screen flex flex-col">
      <div className="flex flex-col md:flex-row justify-center gap-x-20 w-[98%] ml-1 md:ml-0 md:w-full md:px-10 md:py-20 ">
        <div className="w-full lg:w-1/2 flex flex-col items-start">
          <div className=" w-full md:!w-[697px] text-left mb-3 md:!mb-0 md:!mt-0 mt-3">
          <UnderlinedTitle texto = {t('Contacta con nosotros')} blanco={false} palabrasSubrayadas='Contacta con' width='700.48px' marginTop='0px'/>
          </div>
          <div className="w-auto md:!w-[491.2px] mt-2 md:!mt-28 lg:ml-48">
            <Text variant='contacto' className='text-left'>
              {t('¡Haznos saber tus ideas y llevemos tu proyecto al siguiente nivel!')}
            </Text>
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col items-left md:items-center mt-10 lg:mt-0">
          <div className="md:w-[490.26px] border-b border-[#043873] pb-2 md:!pb-5">
            <Text as='h4' className="mb-3 leading-[130%] text-left">
              {t('Detalles del contacto')}
            </Text>
            <ContactoComp color="black" iconoMail={mailIcon} iconoUbi={mapsIcon} />
          </div>
          <FormularioContacto />
        </div>
      </div>
      <div
        id="alert-success"
        style={{ display: showSuccess ? 'block' : 'none' }}
        className="alert alert-success"
      >
        <strong>{t('exito')}</strong> {t('mensajeExito')}
        <div>
          <span className="success-x"></span>
        </div>
      </div>
      <div
        id="alert-error"
        style={{ display: showError ? 'block' : 'none' }}
        className="alert alert-danger"
      >
        <strong>{t('error')}</strong> {t('mensajeError')}
        <div>
          <span className="error-x"></span>
        </div>
      </div>
    </div>
  )
}

export default Contacto
