import React from 'react'
import Text from '../../shared/textStyleDefault/texto'
const GifInicio = (): any => {
  return (
    <div className="relative w-full mx-auto h-[640px] md:h-[1008.701px] overflow-hidden ">
    {/* Fondo GIF */}
     <div className="absolute inset-0 bg-center bg-cover bg-no-repeat opacity-70 bg-[url('/src/assets/images/inicio/gifFondo.gif')]" />
    {/* Degradado de la parte de abajo */}
     <div className="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-[#043873] to-transparent" />
     {/* Fondo azul atenuado */}
     <div className="absolute inset-0 bg-[rgba(4,56,115,0.55)]" />
     {/* circulos con blur */}
     <div className="absolute w-[507px]  h-[551px] bg-[rgba(79,156,249,0.6)] blur-3xl rounded-full  top-[324px] left-[-288px] lg:block hidden" />
     <div className="absolute w-[370px] h-[370px] bg-[rgba(79,156,249,0.6)] blur-3xl rounded-full  top-[-100px] left-[1420px] lg:block hidden" />
     {/* div texto */}
     <div className="content-start items-start justify-center relative z-10 inline-flex flex-col max-w-[994px] max-h-[300px] gap-10 text-white md:items-center md:justify-start  top-[200px]">
       <Text as='h2' className='flex-1 text-center justify-start font-lato font-normal leading-10 text-4xl tracking-tight md:text-6xl md:font-light md:leading-[80px]' >Criterian: Arquitectos de Innovación y Estrategas Digitales.</Text>
       <div className='max-w-[663px] mt-[40px]' >
        <Text as='h5' className=' ml-2 mr-3text-sm font-medium font-Lato leading-[17.25px] tracking-tight md:text-xl md:font-normal '>Desde la estrategia innovadora hasta el desarrollo meticuloso, nuestro compromiso es superar expectativas y crear experiencias tecnológicas. <br></br>¿Tienes un sueño digital? Estamos aquí para convertirlo en realidad.<br></br>¿Cómo podemos impulsar tu visión hoy?</Text>
       </div>
      </div>
  </div>
  )
}
export default GifInicio
