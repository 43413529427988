import React from 'react'
import constantes from '../../constantes/constantes'
import UnderlinedTitle from '../../shared/underlinedText/underlined-title'
import Text from '../../shared/textStyleDefault/texto'

const razones = constantes.razones

const RazonesCriterian = (): any => {
  return (
    <div className="inline-flex flex-col items-start gap-[65px] w-full bg-[#043873] px-4 py-8">
      <UnderlinedTitle blanco={true} texto='¿Por qué Criterian?' palabrasSubrayadas='Criterian' width='993.70px' marginTop='3%'/>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-[40px] w-full max-w-[1250px] mx-auto mb-16">
        {razones.map((razon) => (
          <div key={razon.icono} className="flex flex-col items-center gap-[12.6px] md:gap-[16px] text-center">
            <div>
              <img src={razon.icono} alt="icono" className=" gap-[10px] size-[33px] md:size-[64px]" />
            </div>
            <Text variant='porquetitulo' className="self-stretch text-center justify-start text-white text-xs  font-['Lato'] leading-[15px] tracking-tight">{razon.titulo}</Text>
            <Text variant='porque' className=' not-italic self-stretch text-center  justify-start text-white font-["Lato"] leading-[10px] md:leading-[20px] tracking-[0.024px]'>{razon.texto}</Text>
          </div>
        ))}
      </div>
    </div>
  )
}
export default RazonesCriterian
