import React from 'react'
import NavbarLinks from '../header/navbar-links'
const FooterOptions = (): any => {
  return (
      <div className='w-48 mt-[55px] lg:mt-[30%]'>
            <div>
              <NavbarLinks color='black' variant="footer" />
            </div>
        </div>
  )
}
export default FooterOptions
